import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Title from '../components/title';

import {withStyles} from '@material-ui/core/styles';


const styles = theme => ({
  header: {fontSize: 24, marginBottom: "0.8em"},
  paragraph: {marginBottom: "2em"}
});

const Page = ({classes}) => (
  <Layout>
    <SEO title="Kontakt"
         keywords={[`Paczki`, `Anglia`, `Paczki Polska Anglia`, `Paczki Anglia`, `UK-TRANS`, `Przewozy Okonek`, `Przewozy-Okonek`, `Przewozy.okonek`, `Przeprowadzki anglia.`]}/>
    <Title>BUSY I PRZEWOZY DO ANGLII I POLSKI</Title>
    <div className="container">
      <p className="text-center">
        <div><strong>UK-TRANS</strong></div>
        <div>ul. Niepodległości 63</div>
        <div>64-965 OKONEK</div>
        <div>Polska</div>
        <div>NIP: 767-164-96-65</div>
        <div><strong>DANE DO PRZELEWU:</strong></div>
        <div>USŁUGI TRANSPORTOWE UK-TRANS</div>
        <div>WOJCIECH SOBECKI</div>
        <div>UL. NIEPODLEGŁOŚCI 63</div>
        <div>64-965 OKONEK</div>
        <div>ALIOR BANK</div>
        <div><strong>17 2490 0005 0000 4510 6237 7731</strong></div>
        <div>w tytule proszę wpisać:</div>
        <div>Imię, nazwisko, adres, opłata za przesyłkę.</div>
      </p>
    </div>
  </Layout>
);

export default withStyles(styles)(Page);